import React, { useId } from 'react';
import { observer } from 'bb/app/stores';
import { HeaderSection } from 'bb/discovery/components/HeaderSection';
import { SubscriptionProducts } from 'bb/discovery/start/components/SubscriptionProducts';
import { useTranslation } from 'bb/i18n';
import { useSubscriptionStore } from 'bb/subscription';
import { type SubscriptionProducts as SubscriptionProductsType } from 'bb/subscription/types';

export type SubscriptionsContentProps = Pick<
    SubscriptionProductsType,
    'trialHours' | 'trialDays'
>;

const SubscriptionsContent = (props: SubscriptionsContentProps) => {
    const { trialDays, trialHours } = props;
    const { t } = useTranslation();
    const labelId = useId();

    return (
        <HeaderSection
            heading={{
                t: 'start:subscriptionHeaderTrial',
                values: { trialDays }
            }}
            subHeading={{
                t: 'start:subscriptionTextTrial',
                values: { numberOfHours: trialHours }
            }}
            data-optimizely="subscriptions-section"
        >
            <SubscriptionProducts
                buttonText={t('cta:fallback')}
                labelledby={labelId}
            />
        </HeaderSection>
    );
};

export type SubscriptionsProps = SubscriptionsContentProps;

export const Subscriptions = observer((props: SubscriptionsProps) => {
    const subscriptionStore = useSubscriptionStore();

    if (subscriptionStore.subscriptionProducts?.products?.length > 1) {
        return <SubscriptionsContent {...props} />;
    }
    return null;
});
